<template>
  <div>
    <div class="z-0 h-screen">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2"
        v-if="!getMenu"
      >
        <div class="py-1 max-w-5xl w-full flex-none">
          <div class="p-4">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
        </div>

        <div class="mt-16">
          <h3 class="text-5xl font-serif font-extrabold">Account</h3>

          <div class="grid grid-cols-1 gap-10 lg:grid-cols-4 mt-10">
            <div class="w-full bg-gray-100 text-gray-600 rounded">
              <router-link to="/user/account" class="cursor-pointer">
                <div class="p-2 text-xl">
                  <div class="p-5 bg-gray-200 rounded">Account</div>
                </div>
              </router-link>
              <router-link to="/user/order" class="cursor-pointer">
                <div class="p-2 text-xl">
                  <div class="p-5">Order</div>
                </div>
              </router-link>
              <router-link to="/user/setting" class="cursor-pointer">
                <div class="p-2 text-xl">
                  <div class="p-5">Setting</div>
                </div>
              </router-link>
              <router-link to="/user/credits" class="cursor-pointer">
                <div class="p-2 text-xl">
                  <div class="p-5">Credits</div>
                </div>
              </router-link>

              <router-link to="/user/support" class="cursor-pointer">
                <div class="p-2 text-xl">
                  <div class="p-5">Support</div>
                </div>
              </router-link>

              <div
                class="p-2 pb-5 text-xl cursor-pointer"
                @click="logoutData()"
              >
                <div
                  class="inline-block bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl w-full"
                >
                  <div
                    class="inline-block w-full text-base px-2 py-1 sm:px-4 sm:py-3 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900 flex justify-center"
                  >
                    Logout
                  </div>
                </div>
              </div>
            </div>

            <div class="col-span-3 text-gray-700">
              <div>
                <div class="text-2xl font-serif font-extrabold">
                  Account Details
                </div>
              </div>

              <div class="flex mt-10">
                <img class="" :src="getUser.userData.picture" />
              </div>

              <div class="w-full border-2 p-5 mt-5">
                <div class="text-xl">
                  <b class="text-3xl font-serif font-extrabold">Name:</b>
                  {{ getUser.userData.name }}
                </div>
              </div>
              <div class="w-full border-2 p-5 mt-5">
                <div class="text-xl">
                  <b class="text-3xl font-serif font-extrabold">Email:</b>
                  {{ getUser.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StickFooter />
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      address: this.$route.params.address,
      event: null,
      dataKYC: {},
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getUser", "getMenu"]),

  methods: {
    ...mapActions([
      "setAccess_token",
      "logout",
      "setMenu",
      "setUser",
      "setToken",
    ]),
    logoutData() {
      this.setUser(null);

      this.setToken(null);

      //   this.setAccess_token();
      // this.logout();
      setTimeout(() => {
        this.$router.push("/");
      }, 300);
    },
  },
  created() {},
};
</script>
